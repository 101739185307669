portfolio-container {
    width: 90%;
    padding: 0 auto;
    margin: 0 auto;
  
    padding-top: 50px;
  }
  
  .row {
    display: flex;
  }
  /* First Container - Single Image */
  .single-image-container {
    width: 35%;
    height: 455px;
    background: linear-gradient(
        180deg,
        rgba(9, 73, 150, 0.7) 0%,
        rgba(255, 255, 255, 0) 49.5%,
        rgba(255, 255, 255, 0) 100%
      ),
      url("..//GrafinImg/newproject.jpg") no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    margin: 5px;
  }
  .container-heading {
    border-radius: 40px;
    border: 1px solid #db07d7;
    width: 250px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    background: linear-gradient(90deg, #136ad5 0%, #db07d7 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .single-image-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    background-size: cover;
    background-position: center;
    transform: scale(1);
    transition: transform 0.75s;
  }
  .add-content1 {
    position: absolute;
    left: 20px;
    top: 0px;
  }
  .add-content1 h1 {
    color: #fff;
    font-family: Poppins;
    font-size: 32px;
    font-weight: 600;
  
  }
  .add-content1 h3 {
    color: #fff;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
  }
  
  .single-image-container:hover::before {
    transform: scale(1.3);
    transition: 0.8s ease-in-out;
  }
  
  /* Second Container - Grid of Images */
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 370px);
    width: 70%;
    margin: 5px 10px;
    position: relative;
    gap: 15px;
  }
  .add-content1 {
    position: absolute;
    left: 20px;
  }
  .add-content2 {
    position: absolute;
    top: 0px;
    left: 20px;
  }
  .add-content3 {
    position: absolute;
    top: 0px;
    left: 20px;
  }
  .add-content4 {
    position: absolute;
    top: 0px;
    left: 20px;
  }
  .add-content5 {
    position: absolute;
    top: 0px;
    left: 20px;
  }
  .add-content6 {
    position: absolute;
    top: 0px;
    left: 20px;
  }
  
  .project-heading1 {
    color: #fff;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 10px;
  }
  .project-heading2 {
    color: #fff;
    font-family: Poppins;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: -35px;
  }
  .project-heading3 {
    color: #fff;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 10px;
  }
  .project-heading4 {
    color: #fff;
    font-family: Poppins;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: -35px;
  }
  .project-heading5 {
    color: #fff;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 10px;
  }
  .project-heading6 {
    color: #fff;
    font-family: Poppins;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: -35px;
  }
  .project-heading7 {
    color: #fff;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 10px;
  }
  .project-heading8 {
    color: #fff;
    font-family: Poppins;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: -35px;
  }
  .project-heading9 {
    color: #fff;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 10px;
  }
  .project-heading10 {
    color: #fff;
    font-family: Poppins;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: -35px;
  }
  
  .grid-container div {
    width: 100%;
    height: 220px;
    border-radius: 15px;
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;
  }
  
  .grid-container div::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    background-size: cover;
    background-position: center;
    transform: scale(1);
    transition: transform 0.75s;
  }
  
  .grid-container div:hover::before {
    transform: scale(1.4); /* Zoom-in effect */
    transition: 0.8s ease-in-out;
  }
  
  /* Example Background URLs */
  .grid-container .grid-item1 {
    border-radius: 10px;
    background: linear-gradient(
        180deg,
        rgba(163, 158, 12, 0.7) 0%,
        rgba(255, 255, 255, 0) 50%,
        rgba(255, 255, 255, 0) 100%
      ),
      url("..//GrafinImg/newproject1.jpg") no-repeat center center;
  }
  
  .grid-container .grid-item2 {
    background: linear-gradient(
        180deg,
        rgba(23, 174, 121, 0.7) 0%,
        rgba(255, 255, 255, 0) 50%,
        rgba(255, 255, 255, 0) 100%
      ),
      url("..//GrafinImg/newproject2.jpg") no-repeat center center;
  }
  
  .grid-container .grid-item3 {
    background: linear-gradient(
        180deg,
        rgba(165, 85, 6, 0.7) 0%,
        rgba(255, 255, 255, 0) 50%,
        rgba(255, 255, 255, 0) 100%
      ),
      url("..//GrafinImg/newproject3.jpg") no-repeat center center;
  }
  
  .grid-container .grid-item4 {
    background: linear-gradient(
        180deg,
        rgba(150, 9, 40, 0.7) 0%,
        rgba(255, 255, 255, 0) 50%,
        rgba(255, 255, 255, 0) 100%
      ),
      url("..//GrafinImg/newproject4.jpg") no-repeat center center;
  }
  
  /* Small Mobile Devices (max-width: 479px) */
  @media (max-width: 479px) {
    .portfolio-container {
      width: 80%;
      margin: 0 auto;
      padding: 0 auto;
    
      
    }
    .products-container{
      width: 160px;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .row {
      display: block;
    }
  
    .single-image-container {
      width: 100%;
      height: 200px;
      background-size: cover;
      margin-bottom: 20px;
    }
    .add-content1 h1 {
      color: #fff;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
    }
    .add-content1 h3 {
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
      margin-top: -20px;
    }
    .add-content2 h1 {
      color: #fff;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
    }
    .add-content2 h3 {
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
      margin-top: -20px;
    }
    .add-content3 h1 {
      color: #fff;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
    }
    .add-content3 h3 {
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
      margin-top: -20px;
    }
    .add-content4 h1 {
      color: #fff;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
    }
    .add-content5 h3 {
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
      margin-top: -20px;
    }
    .add-content5 h1 {
      color: #fff;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
    }
    .add-content4 h3 {
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
      margin-top: -20px;
    }
  
    .grid-container {
      grid-template-columns: 1fr;
      width: 100%;
    }
  
    .grid-container div {
      height: 200px;
      background-size: cover;
      padding-bottom: 20px;
    }
    .container-heading {
      border-radius: 40px;
      border: 1px solid #db07d7;
      width: 150px;
      padding: 5px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      background: linear-gradient(90deg, #136ad5 0%, #db07d7 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  
  /* this is Tablets device */
  @media (min-width: 481px) and (max-width: 768px) {
    .portfolio-container {
      width: 90%;
      padding-top: 0px;
      padding: 0 auto;
      margin: 0 auto;
    }
    .products-container{
      width: 160px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  
    .single-image-container {
      width: 30%;
      height: 300px;
    }
    .add-content1 h1 {
      color: #fff;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      padding-bottom: 22px;
    }
    .add-content1 h3 {
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
    }
    .add-content2 h1 {
      color: #fff;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      padding-bottom: 22px;
    }
    .add-content2 h3 {
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
    }
    .add-content3 h1 {
      color: #fff;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      padding-bottom:22px;
    }
    .add-content3 h3 {
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
    }
    .add-content4 h1 {
      color: #fff;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      padding-bottom: 22px;
    }
    .add-content5 h3 {
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
    }
    .add-content5 h1 {
      color: #fff;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      padding-bottom: 22px;
    }
    .add-content4 h3 {
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
    }
    .container-heading {
      border-radius: 40px;
      border: 1px solid #db07d7;
      width: 180px;
      padding: 5px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-family: Poppins;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      background: linear-gradient(90deg, #136ad5 0%, #db07d7 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  
    .grid-container {
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      width: 70%;
    }
  
    .grid-container div {
      height: 145px;
    }
  }
  
  /* small desktop device*/
  @media (min-width: 769px) and (max-width: 1024px) {
    .portfolio-container {
      width: 90%;
      padding-top: 0px;
      margin: 0 auto;
      padding: 0 auto;
    }
  
    .single-image-container {
      width: 30%;
      height: 300px;
    }
    .products-container{
      width: 160px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .add-content1 h1 {
      color: #fff;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      padding-bottom: 20px;
    }
    .add-content1 h3 {
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
    }
    .add-content2 h1 {
      color: #fff;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      padding-bottom: 20px;
    }
    .add-content2 h3 {
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
    }
    .add-content3 h1 {
      color: #fff;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      padding-bottom: 20px;
    }
    .add-content3 h3 {
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
    }
    .add-content4 h1 {
      color: #fff;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      padding-bottom: 20px;
    }
    .add-content5 h3 {
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
    }
    .add-content5 h1 {
      color: #fff;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      padding-bottom: 20px;
    }
    .add-content4 h3 {
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
    }
    .container-heading {
      border-radius: 40px;
      border: 1px solid #db07d7;
      width: 190px;
      padding: 5px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-family: Poppins;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      background: linear-gradient(90deg, #136ad5 0%, #db07d7 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  
    .grid-container {
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      width: 70%;
    }
  
    .grid-container div {
      height: 145px;
    }
  }
  
  /* this is larger device */
  @media (min-width: 1025px) {
    .portfolio-container {
      width: 90%;
      padding-top: 5px;
      margin: 0 auto;
      padding: 0 auto;
  
    }
    .products-container{
      width: 160px;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    
  
    .single-image-container {
      width: 30%;
      height: 455px;
    }
    .add-content1 h1 {
      color: #fff;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      padding-bottom: 20px;
    }
    .add-content1 h3 {
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
    }
    .add-content2 h1 {
      color: #fff;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      padding-bottom: 20px;
    }
    .add-content2 h3 {
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
    }
    .add-content3 h1 {
      color: #fff;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      padding-bottom: 20px;
    }
    .add-content3 h3 {
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
    }
    .add-content4 h1 {
      color: #fff;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      padding-bottom: 20px;
    }
    .add-content5 h3 {
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
    }
    .add-content5 h1 {
      color: #fff;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      padding-bottom: 20px;
    }
    .add-content4 h3 {
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
    }
    .container-heading {
      border-radius: 40px;
      border: 1px solid #db07d7;
      width: 210px;
      padding: 5px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-family: Poppins;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      background: linear-gradient(90deg, #136ad5 0%, #db07d7 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  
    .grid-container {
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      width: 70%;
    }
  
    .grid-container div {
      height: 220px;
    }
  }
  
  /* Adjust for 4K displays */
  @media (min-width: 2560px) {
    .portfolio-container {
      width: 80%;
      padding-top: 0px;
      margin: 0 auto;
      padding: 0 auto;
    }
    .products-container{
      width: 160px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  
    .single-image-container {
      width: 30%;
      height: 800px;
    }
    .add-content1 h1 {
      color: #fff;
      font-family: Poppins;
      font-size: 50px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .add-content1 h3 {
      color: #fff;
      font-family: Poppins;
      font-size: 34px;
      font-weight: 600;
    }
    .add-content2 h1 {
      color: #fff;
      font-family: Poppins;
      font-size: 50px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .add-content2 h3 {
      color: #fff;
      font-family: Poppins;
      font-size: 34px;
      font-weight: 600;
    }
    .add-content3 h1 {
      color: #fff;
      font-family: Poppins;
      font-size: 50px;
      font-weight: 600;
      margin-bottom: 10px;
      
    }
    .add-content3 h3 {
      color: #fff;
      font-family: Poppins;
      font-size: 34px;
      font-weight: 600;
    }
    .add-content4 h1 {
      color: #fff;
      font-family: Poppins;
      font-size:50px;
      font-weight: 600;
      margin-bottom: 10px;
    
    }
    .add-content5 h3 {
      color: #fff;
      font-family: Poppins;
      font-size: 34px;
      font-weight: 600;
    }
    .add-content5 h1 {
      color: #fff;
      font-family: Poppins;
      font-size: 50px;
      font-weight: 600;
      margin-bottom: 10px;
  
    }
    .add-content4 h3 {
      color: #fff;
      font-family: Poppins;
      font-size:34px;
      font-weight: 600;
    }
    .container-heading {
      border-radius: 40px;
      border: 1px solid #db07d7;
      width: 450px;
      padding: 5px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-family: Poppins;
      font-size: 62px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      background: linear-gradient(90deg, #136ad5 0%, #db07d7 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  
    .grid-container {
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      width: 70%;
    }
  
    .grid-container div {
      height: 395px;
    }
  }
  