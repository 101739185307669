.touch-container {
  width: 90%;
  height: 455px;
  border-radius: 24px;
  background: rgba(212, 213, 216, 0.144);

  margin: 0 auto;
  padding: 0 auto;
}
.one {
  padding-top: 30px;
  padding-bottom: 35px;
}

.touch-row {
  display: flex;
}
.touch-colum1 {
  width: 50%;
  padding: 80px 80px;
  justify-content: center;
}
.touch-colum2 {
  width: 50%;
}
.input-box input:focus ~ label {
  top: 30px;
}

.touch-heading {
  color: white;
  font-family: Poppins;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.touch-para {
  color: white;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
}

.touch-colum2 form {
  display: flex;
  flex-direction: column;
  padding: 30px 30px;
}
.input-box {
  width: 85%;
  height: 95px;
  border-bottom: 1px solid #8c8c8c;
  margin: 4px 0;
  position: relative;
}
.input-box:hover {
  border-bottom: 4px solid var(--primary-base, #548ffc);
  border-radius: 4px;
}

.input-box label {
  position: absolute;
  top: 45px;
  left: 5px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #bebebe;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
}
.input-box input {
  width: 100%;
  height: 180%;
  background: transparent;
  border: none;
  outline: none;
  color: #fff;
  font-size: 18px;
}

.touch-colum2 button {
  display: flex;
  width: 370px;
  height: 50px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  color: #fff;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 400;
  background: transparent;
  border: 2px solid var(--primary-base, #548ffc);
  outline: none;
  border-radius: 10px;
}

.touch-colum2 button:hover {
  background-color: #0056b3;
}
.svg-containers {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 50px;
  padding-top: 50px;
}


.svg-wrapper {
  position: absolute;

  top: 50%; 
  right: 0;
  transform: translateY(-50%);
  animation: none; 
}

.svg-wrapper.animate-svg {
  animation: moveSvg 3s ease-in-out forwards; 
}


@keyframes moveSvg {
  0% {
    right: 0; 
  }
  100% {
    right: 50%; 
  }
}


@media (max-width: 479px) {
  .touch-row {
    flex-direction: column;
  }

  .touch-colum1,
  .touch-colum2 {
    width: 80%;
    padding: 20px;
  }

  .touch-colum1 {
    order: 1;
  }

  .touch-colum2 {
    order: 2;
  }

  .touch-container {
    height: auto;
    width: 80%;
    padding: 10px;
    text-align: center;
  }

  .touch-heading {
    font-size: 28px;
    text-align: center;
  }

  .touch-para {
    font-size: 13px;
    line-height: 28px;
    text-align: center;
  }

  .touch-colum2 form {
    padding: 10px;
  }

  .touch-colum2 button {
    width: 100%;
    font-size: 16px;
  }

  .input-box {
    width: 100%;
  }

  .input-box input {
    font-size: 15px;
  }

  .input-box label {
    font-size: 15px;
  }
  .touch-colum2 button:hover {
    background-color: #0056b3;
  }
  .svg-containers {
    position: relative;
    width: 100%;
    height: auto;
    padding-bottom: 30px;
    padding-top: 30px;
  }
  
  /* SVG Animation */
  .svg-wrapper {
    position: absolute;
    
  
    top: 50%; 
    right: 0;
    transform: translateY(-50%);
    animation: none; 
  }
  
  .svg-wrapper.animate-svg {
    animation: moveSvg 3s ease-in-out forwards; 
  }
  
  /* Keyframes for SVG Movement */
  @keyframes moveSvg {
    0% {
      right: 0; 
    }
    100% {
      right: 50%; 
    }
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .touch-container {
    width: 90%;
    height: auto;
  }

  .touch-row {
    flex-direction: column;
    align-items: center;
  }

  .touch-colum1,
  .touch-colum2 {
    width: 100%;
    padding: 20px;
  }

  .touch-colum1 {
    text-align: center;
  }

  .touch-heading {
    font-size: 32px;
  }

  .touch-para {
    font-size: 18px;
    line-height: 28px;
  }

  .touch-colum2 button {
    width: 100%;
  }

  .input-box {
    width: 100%;
  }

  .input-box input {
    font-size: 16px;
  }

  .input-box label {
    font-size: 18px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .touch-container {
    width: 90%;
    height: 455px;
    border-radius: 24px;
    background: rgba(212, 213, 216, 0.144);

    margin: 0 auto;
    padding: 0 auto;
  }
  .one {
    padding-top: 30px;
    padding-bottom: 35px;
  }

  .touch-row {
    display: flex;
  }
  .touch-colum1 {
    width: 50%;
    padding: 80px 80px;
    justify-content: center;
  }
  .touch-colum2 {
    width: 50%;
  }
  .input-box input:focus ~ label {
    top: 30px;
  }

  .touch-heading {
    color: white;
    font-family: Poppins;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .touch-para {
    color: white;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
  }

  .touch-colum2 form {
    display: flex;
    flex-direction: column;
    padding: 30px 30px;
  }
  .input-box {
    width: 85%;
    height: 95px;
    border-bottom: 1px solid #8c8c8c;
    margin: 4px 0;
    position: relative;
  }
  .input-box:hover {
    border-bottom: 4px solid var(--primary-base, #548ffc);
    border-radius: 4px;
  }

  .input-box label {
    position: absolute;
    top: 45px;
    left: 5px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #bebebe;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
  }
  .input-box input {
    width: 100%;
    height: 180%;
    background: transparent;
    border: none;
    outline: none;
    color: #fff;
    font-size: 18px;
  }

  .touch-colum2 button {
    display: flex;
    width: 285px;
    height: 50px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    color: #fff;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 400;
    background: transparent;
    border: 2px solid var(--primary-base, #548ffc);
    outline: none;
    border-radius: 10px;
  }

  .touch-colum2 button:hover {
    background-color: #0056b3;
  }
  .svg-containers {
    position: relative;
    width: 100%;
    height: auto;
    padding-bottom: 30px;
    padding-top: 30px;
  }
  
  
  .svg-wrapper {
    position: absolute;
    width: 50%;
  
    top: 50%; 
    right: 0;
    transform: translateY(-50%);
    animation: none; 
  }
  
  .svg-wrapper.animate-svg {
    animation: moveSvg 3s ease-in-out forwards; 
  }
  
  
  @keyframes moveSvg {
    0% {
      right: 0; 
    }
    100% {
      right: 50%; 
    }
  }
}

@media (min-width: 1025px) {
  .touch-container {
    width: 90%;
    height: 455px;
    border-radius: 24px;
    background: rgba(212, 213, 216, 0.144);

    margin: 0 auto;
    padding: 0 auto;
  }
  .one {
    padding-top: 30px;
    padding-bottom: 35px;
  }

  .touch-row {
    display: flex;
  }
  .touch-colum1 {
    width: 50%;
    padding: 80px 80px;
    justify-content: center;
  }
  .touch-colum2 {
    width: 50%;
  }
  .input-box input:focus ~ label {
    top: 30px;
  }

  .touch-heading {
    color: #fff;    
    font-family: Poppins;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .touch-para {
    color: #fff;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
  }

  .touch-colum2 form {
    display: flex;
    flex-direction: column;
    padding: 30px 30px;
  }
  .input-box {
    width: 85%;
    height: 95px;
    border-bottom: 1px solid #8c8c8c;
    margin: 4px 0;
    position: relative;
  }
  .input-box:hover {
    border-bottom: 4px solid var(--primary-base, #548ffc);
    border-radius: 4px;
  }

  .input-box label {
    position: absolute;
    top: 45px;
    left: 5px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #bebebe;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
  }
  .input-box input {
    width: 100%;
    height: 180%;
    background: transparent;
    border: none;
    outline: none;
    color: #fff;
    font-size: 18px;
  }

  .touch-colum2 button {
    display: flex;
    width: 370px;
    height: 50px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    color: #fff;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 400;
    background: transparent;
    border: 2px solid var(--primary-base, #548ffc);
    outline: none;
    border-radius: 10px;
  }

  .touch-colum2 button:hover {
    background-color: #0056b3;
  }
}

@media (min-width: 2560px){
  .touch-container {
    width: 80%;
    height: 700px;
    border-radius: 25px;
    background: rgba(212, 213, 216, 0.144);

    margin: 0 auto;
    padding: 0 auto;
  }
  .one {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .touch-row {
    display: flex;
  }
  .touch-colum1 {
    width: 50%;
    padding: 120px 120px;
    text-align: justify;
  }
  .touch-colum2 {
    width: 40%;
    padding: 120px 120px;
  }
  .input-box input:focus ~ label {
    top: 30px;
  }

  .touch-heading {
    color: #fff;    
    font-family: Poppins;
    font-size: 75px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }

  .touch-para {
    color: #fff;
    font-family: Poppins;
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: 70px;
  }

  .touch-colum2 form {
    display: flex;
    flex-direction: column;
    padding: 5px 5px;
  }
  .input-box {
    width: 100%;
    height: 125px;
    border-bottom: 1px solid #8c8c8c;
    margin: 4px 0;
    position: relative;
  }
  .input-box:hover {
    border-bottom: 4px solid var(--primary-base, #548ffc);
    border-radius: 4px;
  }

  .input-box label {
    position: absolute;
    top: 45px;
    left: 5px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #bebebe;
    font-family: Poppins;
    font-size: 38px;
    font-style: normal;
    font-weight: 400;
  }
  .input-box input {
    width: 100%;
    height: 180%;
    background: transparent;
    border: none;
    outline: none;
    color: #fff;
    font-size: 46px;
  }

  .touch-colum2 button {
    display: flex;
    width: 650px;
    height: 80px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    color: #fff;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 400;
    background: transparent;
    border: 2px solid var(--primary-base, #548ffc);
    outline: none;
    border-radius: 10px;
  }

  .touch-colum2 button:hover {
    background-color: #0056b3;
  }
  .svg-containers {
    position: relative;
    width: 100%;
    height: auto;
    padding-bottom: 50px;
    padding-top: 50px;
  }
  
  
  .svg-wrapper {
    position: absolute;
  
    top: 50%; 
    right: 0;
    transform: translateY(-50%);
    animation: none; 
  }
  
  .svg-wrapper.animate-svg {
    animation: moveSvg 5s ease-in-out forwards; 
    width: 40%;
  }
  
  
  @keyframes moveSvg {
    0% {
      right: 0; 
    }
    100% {
      right: 50%; 
    }}
}
