
.container-nav {
  background-color: #181818;
  padding: 10px 0;
  position: fixed;
  top: 0px;
  z-index: 2;
  width: 100%;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 20px;
}
.menu-toggle .close-icon {
  font-size: 24px;
  color: white;
  line-height: 1;
  cursor: pointer;
}

.logo {
  height: 50px;
  width: auto;
}

.nav-links {
  list-style-type: none;
  display: flex;
  gap: 30px;
}

.nav-links li a {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 200;
  line-height: 24px; 
  letter-spacing: -0.196px;
  text-decoration: none;
  
}
li .home:visited {
  color: #a09d9d;
}

.nav-links li a:hover {
  color: #ddd;
}

.menu-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.menu-toggle .bar {
  width: 20px;
  height: 2px;
  background-color: white;
  margin: 4px 0;
}

@media (max-width: 768px) {
  .nav-links {
    position: absolute;
    top: 30px;
    right: 0;
    background-color: #333;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 0;
    overflow: hidden;
    z-index: 2;
    gap: 5px;
    transition: height 0.3s ease;
  }
  .container-nav {
    background-color: #181818;
    padding: 0px 0;
    position: fixed;
    top: 0px;
    z-index: 2;
    width: 100%;
  }

  .nav-links.active {
    height: auto;
    padding: 10px 20px;
    background-color: #181818;
  }

  .nav-links li {
    padding: 1px 0;
    width: 100%;
  }

  .menu-toggle {
    display: flex;
  }

  .navbar ul.nav-links:not(.active) {
    display: none;
  }
}

@media (min-width: 1921px){
  .container-nav {
    background-color: #181818;
    padding: 10px 0;
    position: fixed;
    top: 0px;
    z-index: 2;
    width: 100%;
  }
  
  .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 20px;
  }
  .menu-toggle .close-icon {
    font-size: 24px;
    color: white;
    line-height: 1;
    cursor: pointer;
  }
  
  .logo {
    height: 100px;
    width: auto;
  }
  
  .nav-links {
    list-style-type: none;
    display: flex;
    gap: 30px;
  }
  
  .nav-links li a {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 200;
    line-height: 24px; /* 150% */
    letter-spacing: -0.196px;
    text-decoration: none;
    /* color: white;
        text-decoration: none;
        font-size: 14px; */
  }
  li .home:visited {
    color: #a09d9d;
  }
  
  .nav-links li a:hover {
    color: #ddd;
  }
  
  .menu-toggle {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .menu-toggle .bar {
    width: 20px;
    height: 2px;
    background-color: white;
    margin: 4px 0;
  }

}