
.approach-containermain {
    width: 90%;
    margin: 0 auto;
    padding: 0 auto;
  }
  
  
  
  
  .hover-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    width: 100%;
    padding-top: 50px;
  }
  .approach-container {
    width: 400px;
    padding-top: 50px;
  }
  
  .approach-heading {
    text-align: center;
    margin-bottom: 20px;
    border-radius: 40px;
    border: 1px solid #db07d7;
    width: fit-content;
    padding: 5px 8px;
    margin: 0 auto;
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-weight: 600;
    background: linear-gradient(90deg, #136ad5 0%, #db07d7 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .image-wrapper {
    position: relative;
    width: 100%;
    border-radius: 8px;
    height: 350px;
    overflow: hidden;
  }
  
  .hover-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: filter 0.5s ease-in-out;
  }
  
  .overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(102, 102, 102, 0) 100%
    );
  }
  
  .overlay-header {
    position: absolute;
    top: 0px;
    left: 30px;
    color: white;
    font-size: 28px;
    font-family: Poppins, sans-serif;
    font-weight: 600;
  }
  
  .hover-content1 {
    position: absolute;
    top: 40%;
    left: 41%;
    transform: translate(-50%, -50%);
    opacity: 0;
    color: white;
    font-size: 15px;
    font-weight: 600;
    transition: opacity 0.5s, filter 0.5s;
    filter: blur(10px);
  }
  .hover-content2 {
    position: absolute;
    top: 38%;
    left: 41%;
    transform: translate(-50%, -50%);
    opacity: 0;
    color: white;
    font-size: 15px;
    font-weight: 600;
    transition: opacity 0.5s, filter 0.5s;
    filter: blur(10px);
  }
  .hover-content3 {
    position: absolute;
    top: 36%;
    left: 41%;
    transform: translate(-50%, -50%);
    opacity: 0;
    color: white;
    font-size: 15px;
    font-weight: 600;
    transition: opacity 0.5s, filter 0.5s;
    filter: blur(10px);
  }
  .hover-content4 {
    position: absolute;
    top: 38%;
    left: 41%;
    transform: translate(-50%, -50%);
    opacity: 0;
    color: white;
    font-size: 15px;
    font-weight: 600;
    transition: opacity 0.5s, filter 0.5s;
    filter: blur(10px);
  }
  
  .image-wrapper:hover .hover-image {
    filter: blur(6px);
  }
  
  .image-wrapper:hover .hover-content1 {
    opacity: 1;
    filter: blur(0);
  }
  .image-wrapper:hover .hover-content1 {
    opacity: 1;
    filter: blur(0);
  }
  .image-wrapper:hover .hover-content2 {
    opacity: 1;
    filter: blur(0);
  }
  .image-wrapper:hover .hover-content3 {
    opacity: 1;
    filter: blur(0);
  }
  .image-wrapper:hover .hover-content4 {
    opacity: 1;
    filter: blur(0);
  }
  
  /* Mobile devices (up to 600px) */
   
  @media (max-width: 480px) {
    .approach-containermain {
      width: 80%;
      margin: 0 auto;
      padding: 0 auto;
    }
    
    .hover-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 20px;
      width: 100%;
      padding-top: 30px;
    }
    .approach-container {
      width: 162px;
      padding-top: 30px;
    }
    
    .approach-heading {
      text-align: center;
      margin-bottom: 20px;
      border-radius: 40px;
      border: 1px solid #db07d7;
      width: fit-content;
      padding: 5px 8px;
      margin: 0 auto;
      font-family: Poppins, sans-serif;
      font-size: 20px;
      font-weight: 600;
      background: linear-gradient(90deg, #136ad5 0%, #db07d7 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    
    .image-wrapper {
      position: relative;
      width: 100%;
      border-radius: 8px;
      height: 300px;
      overflow: hidden;
    }
    
    .hover-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: filter 0.6s ease-in-out;
    }
    
    .overlay {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.7) 0%,
        rgba(102, 102, 102, 0) 100%
      );
    }
    
    .overlay-header {
      position: absolute;
      top: 0px;
      left: 30px;
      color: white;
      font-size: 28px;
      font-family: Poppins, sans-serif;
      font-weight: 600;
    }
    
    .hover-content1 {
      position: absolute;
      top: 42%;
      left: 41%;
      transform: translate(-50%, -50%);
      opacity: 0;
      color: white;
      font-size: 14px;
      font-weight: 600;
      transition: opacity 0.5s, filter 0.5s;
      filter: blur(10px);
    }
    .hover-content2 {
      position: absolute;
      top: 42%;
      left: 41%;
      transform: translate(-50%, -50%);
      opacity: 0;
      color: white;
      font-size: 14px;
      font-weight: 600;
      transition: opacity 0.5s, filter 0.5s;
      filter: blur(10px);
    }
    .hover-content3 {
      position: absolute;
      top: 40%;
      left: 41%;
      transform: translate(-50%, -50%);
      opacity: 0;
      color: white;
      font-size: 14px;
      font-weight: 600;
      transition: opacity 0.5s, filter 0.5s;
      filter: blur(10px);
    }
    .hover-content4 {
      position: absolute;
      top: 42%;
      left: 41%;
      transform: translate(-50%, -50%);
      opacity: 0;
      color: white;
      font-size: 14px;
      font-weight: 600;
      transition: opacity 0.5s, filter 0.5s;
      filter: blur(10px);
    }
    
    .image-wrapper:hover .hover-image {
      filter: blur(6px);
    }
    
    .image-wrapper:hover .hover-content1 {
      opacity: 1;
      filter: blur(0);
    }
    .image-wrapper:hover .hover-content1 {
      opacity: 1;
      filter: blur(0);
    }
    .image-wrapper:hover .hover-content2 {
      opacity: 1;
      filter: blur(0);
    }
    .image-wrapper:hover .hover-content3 {
      opacity: 1;
      filter: blur(0);
    }
    .image-wrapper:hover .hover-content4 {
      opacity: 1;
      filter: blur(0);
    }
  
  } 
  
  /* this is taplet device */
  
  @media (min-width: 481px) and (max-width: 768px) {
    .approach-containermain {
      width: 90%;
      margin: 0 auto;
      padding: 0 auto;
    }
    
    .hover-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
      gap: 20px;
      width: 100%;
      padding-top: 5px;
  
    }
    .approach-container {
      width: 210px;
      padding-top: 30px;
      padding-bottom: 30px;
    }
    
    .approach-heading {
      text-align: center;
      margin-bottom: 20px;
      border-radius: 40px;
      border: 1px solid #db07d7;
      width: fit-content;
      padding: 5px 8px;
      margin: 0 auto;
      font-family: Poppins, sans-serif;
      font-size: 26px;
      font-weight: 600;
      background: linear-gradient(90deg, #136ad5 0%, #db07d7 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    
    .image-wrapper {
      position: relative;
      width: 100%;
      border-radius: 8px;
      height: 350px;
      overflow: hidden;
    }
    
    .hover-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: filter 0.6s ease-in-out;
    }
    
    .overlay {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.7) 0%,
        rgba(102, 102, 102, 0) 100%
      );
    }
    
    .overlay-header {
      position: absolute;
      top: 0px;
      left: 30px;
      color: white;
      font-size: 28px;
      font-family: Poppins, sans-serif;
      font-weight: 600;
    }
    
    .hover-content1 {
      position: absolute;
      top: 40%;
      left: 40%;
      transform: translate(-50%, -50%);
      opacity: 0;
      color: white;
      font-size: 15px;
      font-weight: 600;
      transition: opacity 0.5s, filter 0.5s;
      filter: blur(10px);
    }
    .hover-content2 {
      position: absolute;
      top: 34%;
      left: 40%;
      transform: translate(-50%, -50%);
      opacity: 0;
      color: white;
      font-size: 15px;
      font-weight: 600;
      transition: opacity 0.5s, filter 0.5s;
      filter: blur(10px);
    }
    .hover-content3 {
      position: absolute;
      top: 34%;
      left: 38%;
      transform: translate(-50%, -50%);
      opacity: 0;
      color: white;
      font-size: 15px;
      font-weight: 600;
      transition: opacity 0.5s, filter 0.5s;
      filter: blur(10px);
    }
    .hover-content4 {
      position: absolute;
      top: 34%;
      left: 39%;
      transform: translate(-50%, -50%);
      opacity: 0;
      color: white;
      font-size: 15px;
      font-weight: 600;
      transition: opacity 0.5s, filter 0.5s;
      filter: blur(10px);
    }
    
    .image-wrapper:hover .hover-image {
      filter: blur(6px);
    }
    
    .image-wrapper:hover .hover-content1 {
      opacity: 1;
      filter: blur(0);
    }
    .image-wrapper:hover .hover-content1 {
      opacity: 1;
      filter: blur(0);
    }
    .image-wrapper:hover .hover-content2 {
      opacity: 1;
      filter: blur(0);
    }
    .image-wrapper:hover .hover-content3 {
      opacity: 1;
      filter: blur(0);
    }
    .image-wrapper:hover .hover-content4 {
      opacity: 1;
      filter: blur(0);
    }
  } 
  
  /* small desktop device */
   @media (min-width: 769px) and (max-width: 1024px) {
    .approach-containermain {
      width: 90%;
      margin: 0 auto;
      padding: 0 auto;
    }
    
    .hover-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 20px;
      width: 100%;
      padding-top: 40px;
    }
    .approach-container {
      width: 208px;
      padding-top: 30px;
    }
    
    .approach-heading {
      text-align: center;
      margin-bottom: 20px;
      border-radius: 40px;
      border: 1px solid #db07d7;
      width: fit-content;
      padding: 5px 8px;
      margin: 0 auto;
      font-family: Poppins, sans-serif;
      font-size: 26px;
      font-weight: 600;
      background: linear-gradient(90deg, #136ad5 0%, #db07d7 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    
    .image-wrapper {
      position: relative;
      width: 100%;
      border-radius: 8px;
      height: 350px;
      overflow: hidden;
    }
    
    .hover-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: filter 0.6s ease-in-out;
    }
    
    .overlay {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.7) 0%,
        rgba(102, 102, 102, 0) 100%
      );
    }
    
    .overlay-header {
      position: absolute;
      top: 0px;
      left: 20px;
      color: white;
      font-size: 24px;
      font-family: Poppins, sans-serif;
      font-weight: 600;
    }
    
    .hover-content1 {
      position: absolute;
      top: 42%;
      left: 41%;
      transform: translate(-50%, -50%);
      opacity: 0;
      color: white;
      font-size: 15px;
      font-weight: 600;
      transition: opacity 0.5s, filter 0.5s;
      filter: blur(10px);
    }
    .hover-content2 {
      position: absolute;
      top: 42%;
      left: 41%;
      transform: translate(-50%, -50%);
      opacity: 0;
      color: white;
      font-size: 15px;
      font-weight: 600;
      transition: opacity 0.5s, filter 0.5s;
      filter: blur(10px);
    }
    .hover-content3 {
      position: absolute;
      top: 37%;
      left: 41%;
      transform: translate(-50%, -50%);
      opacity: 0;
      color: white;
      font-size: 15px;
      font-weight: 600;
      transition: opacity 0.5s, filter 0.5s;
      filter: blur(10px);
    }
    .hover-content4 {
      position: absolute;
      top: 40%;
      left: 43%;
      transform: translate(-50%, -50%);
      opacity: 0;
      color: white;
      font-size: 15px;
      font-weight: 600;
      transition: opacity 0.5s, filter 0.5s;
      filter: blur(10px);
    }
    
    .image-wrapper:hover .hover-image {
      filter: blur(6px);
    }
    
    .image-wrapper:hover .hover-content1 {
      opacity: 1;
      filter: blur(0);
    }
    .image-wrapper:hover .hover-content1 {
      opacity: 1;
      filter: blur(0);
    }
    .image-wrapper:hover .hover-content2 {
      opacity: 1;
      filter: blur(0);
    }
    .image-wrapper:hover .hover-content3 {
      opacity: 1;
      filter: blur(0);
    }
    .image-wrapper:hover .hover-content4 {
      opacity: 1;
      filter: blur(0);
    }
  } 
  /* this is large desktop device */
   @media (min-width: 1025px) {
    .approach-containermain {
      width: 90%;
      margin: 0 auto;
      padding: 0 auto;
    }
    
    .hover-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 20px;
      width: 100%;
      padding-top: 3px;
    }
    .approach-container {
      width: 240px;
      padding-top: 30px;
      padding-bottom: 30px;
    }
    
    .approach-heading {
      text-align: center;
      margin-bottom: 20px;
      border-radius: 40px;
      border: 1px solid #db07d7;
      width: auto;
      padding: 5px 8px;
      margin: 0 auto;
      font-family: Poppins, sans-serif;
      font-size: 30px;
      font-weight: 600;
      background: linear-gradient(90deg, #136ad5 0%, #db07d7 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    
    .image-wrapper {
      position: relative;
      width: 100%;
      border-radius: 8px;
      height: 350px;
      overflow: hidden;
    }
    
    .hover-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: filter 0.6s ease-in-out;
    }
    
    .overlay {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.7) 0%,
        rgba(102, 102, 102, 0) 100%
      );
    }
    
    .overlay-header {
      position: absolute;
      top: 0px;
      left: 30px;
      color: white;
      font-size: 28px;
      font-family: Poppins, sans-serif;
      font-weight: 600;
    }
    
    .hover-content1 {
      position: absolute;
      top: 41%;
      left: 41%;
      transform: translate(-50%, -50%);
      opacity: 0;
      color: white;
      font-size: 14px;
      font-weight: 600;
      transition: opacity 0.5s, filter 0.5s;
      filter: blur(10px);
      line-height: 22px;
    }
    .hover-content2 {
      position: absolute;
      top: 42%;
      left: 41%;
      transform: translate(-50%, -50%);
      opacity: 0;
      color: white;
      font-size: 14px;
      font-weight: 600;
      transition: opacity 0.5s, filter 0.5s;
      filter: blur(10px);
      line-height: 24px;
    }
    .hover-content3 {
      position: absolute;
      top: 40%;
      left: 41%;
      transform: translate(-50%, -50%);
      opacity: 0;
      color: white;
      font-size: 14px;
      font-weight: 600;
      transition: opacity 0.5s, filter 0.5s;
      filter: blur(10px);
      line-height: 24px;
    }
    .hover-content4 {
      position: absolute;
      top: 42%;
      left: 41%;
      transform: translate(-50%, -50%);
      opacity: 0;
      color: white;
      font-size: 14px;
      font-weight: 600;
      transition: opacity 0.5s, filter 0.5s;
      filter: blur(10px);
      line-height: 24px;
    }
    
    .image-wrapper:hover .hover-image {
      filter: blur(6px);
    }
    
    .image-wrapper:hover .hover-content1 {
      opacity: 1;
      filter: blur(0);
    }
    .image-wrapper:hover .hover-content1 {
      opacity: 1;
      filter: blur(0);
    }
    .image-wrapper:hover .hover-content2 {
      opacity: 1;
      filter: blur(0);
    }
    .image-wrapper:hover .hover-content3 {
      opacity: 1;
      filter: blur(0);
    }
    .image-wrapper:hover .hover-content4 {
      opacity: 1;
      filter: blur(0);
    }
  } 
  
  /* 4K devices (1921px and above) */
   @media (min-width: 2560px) {
    .approach-containermain {
      width: 80%;
      margin: 0 auto;
      padding: 0 auto;
    }
    
    .hover-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 20px;
      width: 100%;
      padding-top: 30px;
    }
    .approach-container {
      width: 500px;
      padding-top: 50px;
    }
    
    .approach-heading {
      text-align: center;
      margin-bottom: 20px;
      border-radius: 40px;
      border: 1px solid #db07d7;
      width: fit-content;
      padding: 5px 8px;
      margin: 0 auto;
      font-family: Poppins, sans-serif;
      font-size: 65px;
      font-weight: 600;
      background: linear-gradient(90deg, #136ad5 0%, #db07d7 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    
    .image-wrapper {
      position: relative;
      width: 100%;
      border-radius: 8px;
      height: 700px;
      overflow: hidden;
    }
    
    .hover-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: filter 0.6s ease-in-out;
    }
    
    .overlay {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.7) 0%,
        rgba(102, 102, 102, 0) 100%
      );
    }
    
    .overlay-header {
      position: absolute;
      top: 0px;
      left: 30px;
      color: white;
      font-size: 58px;
      font-family: Poppins, sans-serif;
      font-weight: 600;
    }
    
    .hover-content1 {
      position: absolute;
      top: 42%;
      left: 38%;
      transform: translate(-50%, -50%);
      opacity: 0;
      color: white;
      font-size: 28px;
      font-weight: 600;
      line-height: 45px;
      transition: opacity 0.5s, filter 0.5s;
      filter: blur(10px);
    }
    .hover-content2 {
      position: absolute;
      top: 42%;
      left: 37%;
      transform: translate(-50%, -50%);
      opacity: 0;
      color: white;
      font-size: 28px;
      font-weight: 600;
      line-height: 45px;
      transition: opacity 0.5s, filter 0.5s;
      filter: blur(10px);
    }
    .hover-content3 {
      position: absolute;
      top: 41%;
      left: 38%;
      transform: translate(-50%, -50%);
      opacity: 0;
      color: white;
      font-size: 28px;
      font-weight: 600;
      line-height: 45px;
      transition: opacity 0.5s, filter 0.5s;
      filter: blur(10px);
    }
    .hover-content4 {
      position: absolute;
      top: 41%;
      left: 37%;
      transform: translate(-50%, -50%);
      opacity: 0;
      color: white;
      font-size: 28px;
      font-weight: 600;
      line-height: 45px;
      transition: opacity 0.5s, filter 0.5s;
      filter: blur(10px);
    }
    
    .image-wrapper:hover .hover-image {
      filter: blur(6px);
    }
    
    .image-wrapper:hover .hover-content1 {
      opacity: 1;
      filter: blur(0);
    }
    .image-wrapper:hover .hover-content1 {
      opacity: 1;
      filter: blur(0);
    }
    .image-wrapper:hover .hover-content2 {
      opacity: 1;
      filter: blur(0);
    }
    .image-wrapper:hover .hover-content3 {
      opacity: 1;
      filter: blur(0);
    }
    .image-wrapper:hover .hover-content4 {
      opacity: 1;
      filter: blur(0);
    }
   
  
  } 
  
  