.container-video {
    width: 100%;
    margin: 0 auto;
    padding: 0 auto;
    overflow: hidden;
  }
  
  .video video {
    width: 100%;
    height: auto;
    opacity: 0.6;
    position: relative;
  }
  
  .video-text {
    position: absolute;
    top: 50px;
    left: 30px;
    transform: translate(-50%, -50%);
    color: #fff;
  }
  .video-para {
    position: absolute;
    top: 80px;
    left: 23px;
    transform: translate(-50%, -50%);
    color: #fff;
  }
  .para {
    color: #fff;
    font-family: "Palanquin Dark";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px;
  }
  
  .video-fond1 {
    color: #fff;
    font-family: "Palanquin Dark";
    font-size: 80px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  /* Mobile Devices (up to 480px) */
  @media (max-width: 480px) {
    .video video {
      height: auto;
      opacity: 0.8;
    }
    .video-text {
      top: 105px;
      left: 95px;
      font-size: 18px;
    }
    .video-para {
      top: 155px;
      left: 95px;
      font-size: 14px;
    }
    .para {
      font-size: 12px;
      line-height: 15px;
    }
    .video-fond1 {
      font-size: 22px;
      line-height: 1.2;
    }
  }
  
  /* Tablets (481px to 768px) */
  @media (min-width: 481px) and (max-width: 768px) {
    .video video {
      height: auto;
      opacity: 0.7;
    }
    .video-text {
      top: 190px;
      left: 210px;
      font-size: 22px;
    }
    .video-para {
      top: 290px;
      left: 160px;
      font-size: 18px;
    }
    .para {
      font-size: 18px;
      line-height: 26px;
    }
    .video-fond1 {
      font-size: 48px;
    }
  }
  
  /* Small Desktops (769px to 1024px) */
  @media (min-width: 769px) and (max-width: 1024px) {
    .container-video {
      width: 100%;
      margin: 0 auto;
      padding: 0 auto;
      overflow: hidden;
    }
    .video video {
      width: 100%;
      height: auto;
      opacity: 0.6;
      position: relative;
    }
  
    .video-text {
      top: 310px;
      left: 300px;
      font-size: 28px;
    }
    .video-para {
      top: 462px;
      left: 230px;
      font-size: 22px;
    }
    .para {
      font-size: 28px;
    }
    .video-fond1 {
      font-size: 70px;
    }
  }
  
  /* Large Screens and 4K (1025px and above) */
  @media (min-width: 1025px) {
    .video-text {
      top: 430px;
      left: 360px;
      font-size: 32px;
    }
    .video-para {
      top: 600px;
      left: 280px;
      font-size: 26px;
    }
    .para {
      font-size: 32px;
    }
    .video-fond1 {
      font-size: 80px;
    }
  }
  /* this is 4k device */
  @media (min-width: 1921px) {
    .video-text {
      top: 985px;
      left: 475px;
      font-size: 32px;
    }
    .video-para {
      top: 1220px;
      left: 400px;
      font-size: 26px;
    }
    .para {
      font-size: 45px;
      line-height: 76px;
    }
    .video-fond1 {
      font-size: 100px;
    }
  }
  