 .containers {
    width: 90%;
    margin:0 auto;
    padding:0 auto;
    margin-top: 30px;
  }
  .process-heading {
    border-radius: 40px;
    border: 1px solid #db07d7;
    width: 300px;
    padding:10px;
    justify-content: center;
    align-items: center;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    background: linear-gradient(90deg, #136ad5 0%, #db07d7 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .rows {
    display: flex;
  }
  .colums-1 {
    width: 50%;
    position: relative;
  }

  .overlay1 {
    position: absolute;
    top: 429px;
    left:305px;
    width: 100%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-weight: bold;
    background: linear-gradient(181deg, rgba(255, 255, 255, 0.00) 4.06%, rgba(255, 255, 255, 0.00) 39.28%, rgba(0, 0, 0, 0.61) 84.29%, rgba(0, 0, 0, 0.65) 98.97%);
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  }
  .overlay-heading1 {
    color: #FFF;
    font-family: Enriqueta;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .overlay-heading2 {
    color: #B8B7B7;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: -20px;
  }
  .colum1-image {
    width: 100%;
    height: 500px;
    background-size: cover;
  }
  .process-image{
    width: 575px;
  }
  .colums-2 {
    width: 50%;
    position: relative;

  }
  .overlay2 {
    position: absolute;
    top: 435px;
    left: 285px;
    width: 100%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-weight: bold;
    background: linear-gradient(181deg, rgba(255, 255, 255, 0.00) 4.06%, rgba(255, 255, 255, 0.00) 39.28%, rgba(0, 0, 0, 0.61) 84.29%, rgba(0, 0, 0, 0.65) 98.97%);
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  }
  .overlay-heading11{
    color: #FFF;
    font-family: Enriqueta;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .process-container{
    padding-bottom: 20px;
  }
  .overlay-heading22{
    color: #B8B7B7;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: -20px;
}


  .colum2-image{
    width: 100%;
    height: 500px;
    background-size: cover;
  }
  .colums-11 {
    width: 50%;
  }
  .parent-img1 {
    position: relative;
    width: 570px;
    height: 200px;
  }
  .inner-text {
    position: absolute;
    top: 145px;
    left: 285px;
    width: 100%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-weight: bold;
    background: linear-gradient(181deg, rgba(255, 255, 255, 0.00) 4.05%, rgba(255, 255, 255, 0.00) 39.28%, rgba(0, 0, 0, 0.61) 84.29%, rgba(0, 0, 0, 0.65) 98.97%);
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  }
  .inner1 {
    color: #fff;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 1px;
  }
  .inner2 {
    color: #B8B7B7;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    margin-top: -20px;
  }
  .parent-img2 {
    position: relative;
    width: 570px;
    height: 200px;
    }
  .second-inner {
    position: absolute;
    top: 135px;
    left: 285px;
    width: 100%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-weight: bold;
    background: linear-gradient(181deg, rgba(255, 255, 255, 0.00) 4.06%, rgba(255, 255, 255, 0.00) 39.28%, rgba(0, 0, 0, 0.61) 84.29%, rgba(0, 0, 0, 0.65) 98.97%);
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  }
  .inner3 {
    color: #fff;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 1px;
  }
  .inner4 {
    color: #B8B7B7;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    margin-top: -20px;
  }
  
  .colums-12 {
    width: 50%;
    position: relative;
  }

  .images-1 {
    width: 100%;
    height: 400px;
  }
  .images1 {
    width: 100%;
    height: 300px;
    background-size:cover;

  }
  .images2 {
    width: 100%;
    height: 200px;
    background-size: cover;
  }
  
  .overlay-div1 {
    color: #fff;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 1px;
  }
  .overlay-div2 {
    color: #B8B7B7;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    margin-top: -20px;
  }
  .inner-content {
    position: absolute;
    top: 340px;
    left: 285px;
    width: 100%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-weight: bold;
    background: linear-gradient(181deg, rgba(255, 255, 255, 0.00) 4.06%, rgba(255, 255, 255, 0.00) 39.28%, rgba(0, 0, 0, 0.61) 84.29%, rgba(0, 0, 0, 0.65) 98.97%);
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  }
  .inner-div1 {
    color: #fff;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 1px;
  }
  .inner-div2 {
    color: #B8B7B7;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    margin-top: -20px;
  } 
 
  @media screen and (max-width: 479px) {
    .rows {
      flex-direction: column; 
    }
    .containers{
      width: 80%;
      margin: 0 auto;
      padding: 0 auto;
    }
    .process-heading {
      border-radius: 40px;
      border: 1px solid #db07d7;
      width: 200px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      background: linear-gradient(90deg, #136ad5 0%, #db07d7 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .overlay1 {
      position: absolute;
      top: 429px;
      left:305px;
      width: 100%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-weight: bold;
      background: linear-gradient(181deg, rgba(255, 255, 255, 0.00) 4.06%, rgba(255, 255, 255, 0.00) 39.28%, rgba(0, 0, 0, 0.61) 84.29%, rgba(0, 0, 0, 0.65) 98.97%);
    
      text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    }
    .overlay-heading1 {
      color: #FFF;
      font-family: Enriqueta;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding-left: 12px;
    }
    .overlay-heading2 {
      color: #B8B7B7;
      font-family: Poppins;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-left: 12px;
      padding-top: 12px;
      
    }
    .overlay-heading21{
      color: #FFF;
      font-family: Enriqueta;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding-left: 12px;
  
    }
    .overlay-heading22{
      color: #B8B7B7;
      font-family: Poppins;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-left: 12px;
      padding-top: 12px;

    }
    .inner1 {
      color: #fff;
      font-family:  Enriqueta;
      font-size: 20px;
      font-weight: 600;
      line-height: 25px;
      letter-spacing: 1px;
      padding-left: 12px;
    
    }
    .inner2 {
      color: #B8B7B7;
      font-family: Poppins;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-left: 12px;
      padding-top: 12px;

    }
 
    .inner3 {
      color: #fff;
      font-family:  Enriqueta;
      font-size: 20px;
      font-weight: 600;
      line-height: 25px;
      letter-spacing: 1px;
      padding-left: 12px;
    }
    .inner4 {
      color: #B8B7B7;
      font-family: Poppins;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-left: 12px;
      padding-top: 12px;


    }
    .inner-div1 {
      color: #fff;
      font-family:  Enriqueta;
      font-size: 22px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 1px;
      padding-left: 12px;

    }
    .inner-div2 {
      color: #B8B7B7;
      font-family: Poppins;
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;
      padding-left: 12px;
      padding-top: 12px;


    } 
    .process-container{
      padding-bottom: 20px;
      padding-top: 20px;
    }
    
    


    .process-image{
      width: 100%;
    }
  
    .colums-1, .colums-2, .colums-11, .colums-12 {
      width: 100%; 
      margin-bottom: 20px; 
    }
  
    .parent-img1, .parent-img2 {
      width: 100%; 
      height: auto; 
    }
  
 
  
    .inner-text, .second-inner, .inner-content {
      top: 70%; 
      left: 50%;
      transform: translate(-50%, -50%);
    }
  
    .colum1-image, .colum2-image, .images1, .images2 {
      width: 100%; 
      height: auto; 
    }
    .images-1{
      width: 100%;
      height: 200px;
    }
  
    .overlay1, .overlay2 {
      top: 71%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
 
  }
  @media (min-width: 481px) and (max-width: 768px) {
    .rows {
      flex-direction: row; 
    }
    .containers{
      width: 90%;
      margin: 0 auto;
      padding: 0 auto;
      padding-top: 5px;
    }
    .process-heading {
      border-radius: 40px;
      border: 1px solid #db07d7;
      width: 200px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      background: linear-gradient(90deg, #136ad5 0%, #db07d7 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .overlay1 {
      position: absolute;
      top: 429px;
      left:305px;
      width: 100%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-weight: bold;
      background: linear-gradient(181deg, rgba(255, 255, 255, 0.00) 4.06%, rgba(255, 255, 255, 0.00) 39.28%, rgba(0, 0, 0, 0.61) 84.29%, rgba(0, 0, 0, 0.65) 98.97%);
    
      text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    }
    .overlay-heading1 {
      color: #FFF;
      font-family: Enriqueta;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding-left: 12px;
    }
    .overlay-heading2 {
      color: #B8B7B7;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-left: 12px;
      padding-top: 12px;
      
    }
    .overlay-heading21{
      color: #FFF;
      font-family: Enriqueta;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding-left: 12px;
  
    }
    .overlay-heading22{
      color: #B8B7B7;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-left: 12px;
      padding-top: 12px;

    }
    .inner1 {
      color: #fff;
      font-family:  Enriqueta;
      font-size: 24px;
      font-weight: 600;
      line-height: 25px;
      letter-spacing: 1px;
      padding-left: 12px;
    
    }
    .inner2 {
      color: #B8B7B7;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-left: 12px;
      padding-top: 12px;

    }
 
    .inner3 {
      color: #fff;
      font-family:  Enriqueta;
      font-size: 24px;
      font-weight: 600;
      line-height: 25px;
      letter-spacing: 1px;
      padding-left: 12px;
    }
    .inner4 {
      color: #B8B7B7;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-left: 12px;
      padding-top: 12px;


    }
    .inner-div1 {
      color: #fff;
      font-family:  Enriqueta;
      font-size: 24px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 1px;
      padding-left: 12px;

    }
    .inner-div2 {
      color: #B8B7B7;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      padding-left: 12px;
      padding-top: 12px;


    } 
    .process-container{
      padding-bottom: 20px;
      padding-top: 20px;
    }
    
    


    .process-image{
      width: 101%;
    }
  
    .colums-1, .colums-2, .colums-11, .colums-12 {
      width: 100%; 
      
    }
  
    .parent-img1, .parent-img2 {
      width: 100%; 
      height: auto; 
    }
  
 
  
    .inner-text, .second-inner {
      top: 63%; 
      left: 50%;
      transform: translate(-50%, -50%);
    }
  
    .colum1-image, .colum2-image {
      width: 100%; 
      height: 300px; 
    }
    .images1, .images2{
      width: 100%;
      height: 150px;
    }
    .images-1{
      width: 100%;
      height: 300px;
    }
  
    .overlay1, .overlay2,.inner-content {
      top: 81%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

  } 
  @media (min-width: 769px) and (max-width: 1024px){
    .rows {
      flex-direction: row; 
    }
    .containers{
      width: 90%;
      margin: 0 auto;
      padding: 0 auto;
      padding-top: 2px;
    }
    .process-heading {
      border-radius: 40px;
      border: 1px solid #db07d7;
      width: 260px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      font-family: Poppins;
      font-size: 26px;
      font-weight: 600;
      background: linear-gradient(90deg, #136ad5 0%, #db07d7 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .overlay1 {
      position: absolute;
      top: 429px;
      left:305px;
      width: 100%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-weight: bold;
      background: linear-gradient(181deg, rgba(255, 255, 255, 0.00) 4.06%, rgba(255, 255, 255, 0.00) 39.28%, rgba(0, 0, 0, 0.61) 84.29%, rgba(0, 0, 0, 0.65) 98.97%);
    
      text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    }
    .overlay-heading1 {
      color: #FFF;
      font-family: Enriqueta;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding-left: 12px;
    }
    .overlay-heading2 {
      color: #B8B7B7;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-left: 12px;
      padding-top: 2px;
      
    }
    .overlay-heading21{
      color: #FFF;
      font-family: Enriqueta;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding-left: 12px;
  
    }
    .overlay-heading22{
      color: #B8B7B7;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-left: 12px;
      padding-top: 2px;

    }
    .inner1 {
      color: #fff;
      font-family:  Enriqueta;
      font-size: 26px;
      font-weight: 600;
      line-height: 25px;
      letter-spacing: 1px;
      padding-left: 12px;
    
    }
    .inner2 {
      color: #B8B7B7;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-left: 12px;
      padding-top: 2px;

    }
 
    .inner3 {
      color: #fff;
      font-family:  Enriqueta;
      font-size: 26px;
      font-weight: 600;
      line-height: 25px;
      letter-spacing: 1px;
      padding-left: 12px;
    }
    .inner4 {
      color: #B8B7B7;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-left: 12px;
      padding-top: 2px;


    }
    .inner-div1 {
      color: #fff;
      font-family:  Enriqueta;
      font-size: 26px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 1px;
      padding-left: 12px;

    }
    .inner-div2 {
      color: #B8B7B7;
      font-family: Poppins;
      font-size: 18px;
      font-weight: 600;
      line-height: 20px;
      padding-left: 12px;
      padding-top: 2px;


    } 
    .process-container{
      padding-bottom: 20px;
      padding-top: 20px;
    }
    
    


    .process-image{
      width: 101%;
    }
  
    .colums-1, .colums-2, .colums-11, .colums-12 {
      width: 100%; 
      
    }
  
    .parent-img1, .parent-img2 {
      width: 100%; 
      height: auto; 
    }
  
 
  
    .inner-text, .second-inner {
      top: 70%; 
      left: 50%;
      transform: translate(-50%, -50%);
    }
  
    .colum1-image, .colum2-image {
      width: 100%; 
      height: 400px; 
    }
    .images1, .images2{
      width: 100%;
      height: 200px;
    }
    .images-1{
      width: 100%;
      height: 400px;
    }
  
    .overlay1, .overlay2,.inner-content {
      top: 86%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

  }
  @media (min-width: 1025px){
    .rows {
      flex-direction: row; 
    }
    .containers{
      width: 90%;
      margin: 0 auto;
      padding: 0 auto;
      padding-top: 2px;
    }
    .process-heading {
      border-radius: 40px;
      border: 1px solid #db07d7;
      width: 300px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      font-family: Poppins;
      font-size: 30px;
      font-weight: 600;
      background: linear-gradient(90deg, #136ad5 0%, #db07d7 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .overlay1 {
      position: absolute;
      top: 429px;
      left:305px;
      width: 100%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-weight: bold;
      background: linear-gradient(181deg, rgba(255, 255, 255, 0.00) 4.06%, rgba(255, 255, 255, 0.00) 39.28%, rgba(0, 0, 0, 0.61) 84.29%, rgba(0, 0, 0, 0.65) 98.97%);
    
      text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    }
    .overlay-heading1 {
      color: #FFF;
      font-family: Enriqueta;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 10px;
      padding-left: 12px;
    }
    .overlay-heading2 {
      color: #B8B7B7;
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-left: 12px;
      padding-top: 12px;
      
    }
    .overlay-heading21{
      color: #FFF;
      font-family: Enriqueta;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 10px;
      padding-left: 12px;
  
    }
    .overlay-heading22{
      color: #B8B7B7;
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-left: 12px;
      padding-top: 12px;

    }
    .inner1 {
      color: #fff;
      font-family:  Enriqueta;
      font-size: 32px;
      font-weight: 600;
      line-height: 10px;
      letter-spacing: 1px;
      padding-left: 12px;
    
    }
    .inner2 {
      color: #B8B7B7;
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-left: 12px;
      padding-top: 12px;

    }
 
    .inner3 {
      color: #fff;
      font-family:  Enriqueta;
      font-size: 32px;
      font-weight: 600;
      line-height: 10px;
      letter-spacing: 1px;
      padding-left: 12px;
    }
    .inner4 {
      color: #B8B7B7;
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-left: 12px;
      padding-top: 12px;


    }
    .inner-div1 {
      color: #fff;
      font-family:  Enriqueta;
      font-size: 32px;
      font-weight: 600;
      line-height: 10px;
      letter-spacing: 1px;
      padding-left: 12px;

    }
    .inner-div2 {
      color: #B8B7B7;
      font-family: Poppins;
      font-size: 22px;
      font-weight: 500;
      line-height: normal;
      padding-left: 12px;
      padding-top: 12px;


    } 
    .process-container{
      padding-bottom: 10px;
      padding-top: 10px;
    }
    
    


    .process-image{
      width: 101%;
    }
  
    .colums-1, .colums-2, .colums-11, .colums-12 {
      width: 100%; 
      
    }
  
    .parent-img1, .parent-img2 {
      width: 100%; 
      height: auto; 
    }
  
 
  
    .inner-text, .second-inner {
      top: 72%; 
      left: 50%;
      transform: translate(-50%, -50%);
    }
  
    .colum1-image, .colum2-image {
      width: 100%; 
      height: 500px; 
    }
    .images1, .images2{
      width: 100%;
      height: 250px;
    }
    .images-1{
      width: 100%;
      height: 500px;
    }
  
    .overlay1, .overlay2,.inner-content {
      top: 86%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  
  @media (min-width: 2560px){
    .rows {
      flex-direction: row; 
    }
    .containers{
      width: 80%;
      margin: 0 auto;
      padding: 0 auto;
      padding-top: 2px;
    }
    .process-heading {
      border-radius: 40px;
      border: 1px solid #db07d7;
      width: 600px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      font-family: Poppins;
      font-size: 60px;
      font-weight: 600;
      background: linear-gradient(90deg, #136ad5 0%, #db07d7 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .overlay1 {
      position: absolute;
      top: 429px;
      left:305px;
      width: 100%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-weight: bold;
      background: linear-gradient(181deg, rgba(255, 255, 255, 0.00) 4.06%, rgba(255, 255, 255, 0.00) 39.28%, rgba(0, 0, 0, 0.61) 84.29%, rgba(0, 0, 0, 0.65) 98.97%);
    
      text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    }
    .overlay-heading1 {
      color: #FFF;
      font-family: Enriqueta;
      font-size: 42px;
      font-style: normal;
      font-weight: 600;
      line-height:10px;
      padding-left: 12px;
    }
    .overlay-heading2 {
      color: #B8B7B7;
      font-family: Poppins;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-left: 12px;
      padding-top: 12px;
      
    }
    .overlay-heading21{
      color: #FFF;
      font-family: Enriqueta;
      font-size: 42px;
      font-style: normal;
      font-weight: 600;
      line-height: 10px;
      padding-left: 12px;
  
    }
    .overlay-heading22{
      color: #B8B7B7;
      font-family: Poppins;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-left: 12px;
      padding-top: 12px;

    }
    .inner1 {
      color: #fff;
      font-family:  Enriqueta;
      font-size: 42px;
      font-weight: 600;
      line-height: 10px;
      letter-spacing: 1px;
      padding-left: 12px;
    
    }
    .inner2 {
      color: #B8B7B7;
      font-family: Poppins;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-left: 12px;
      padding-top: 12px;

    }
 
    .inner3 {
      color: #fff;
      font-family:  Enriqueta;
      font-size: 42px;
      font-weight: 600;
      line-height: 10px;
      letter-spacing: 1px;
      padding-left: 12px;
    }
    .inner4 {
      color: #B8B7B7;
      font-family: Poppins;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-left: 12px;
      padding-top: 12px;


    }
    .inner-div1 {
      color: #fff;
      font-family:  Enriqueta;
      font-size: 42px;
      font-weight: 600;
      line-height: 10px;
      letter-spacing: 1px;
      padding-left: 12px;

    }
    .inner-div2 {
      color: #B8B7B7;
      font-family: Poppins;
      font-size: 30px;
      font-weight: 500;
      line-height: normal;
      padding-left: 12px;
      padding-top: 12px;


    } 
    .process-container{
      padding-bottom: 20px;
      padding-top: 20px;
    }
    
    


    .process-image{
      width: 101%;
    }
  
    .colums-1, .colums-2, .colums-11, .colums-12 {
      width: 100%; 
      
    }
  
    .parent-img1, .parent-img2 {
      width: 100%; 
      height: auto; 
    }
  
 
  
    .inner-text, .second-inner {
      top: 77%; 
      left: 50%;
      transform: translate(-50%, -50%);
    }
  
    .colum1-image, .colum2-image {
      width: 100%; 
      height: 800px; 
    }
    .images1, .images2{
      width: 100%;
      height: 400px;
    }
    .images-1{
      width: 100%;
      height: 800px;
    }
  
    .overlay1, .overlay2,.inner-content {
      top: 88%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    

  }