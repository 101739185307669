@keyframes drawLine {
    0% {
      stroke-dasharray: 0 1000;
    }
    100% {
      stroke-dasharray: 1000 0;
    }
  }
  
  .svg-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 50px;
  }
  
   .svg-section:hover .draw {
    animation: drawLine 6s forwards;
  }
  
  .svg-box {
    width: 33.3%;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .svg-font {
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 45px;
    font-weight: 700;
    background: linear-gradient(105deg, #7f8dea 2.35%, #ec72dc 96.13%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  } 
  @media (max-width: 480px){
    .svg-container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding-top: 5px;
      
      }
      
       .svg-section:hover .draw {
        animation: drawLine 6s forwards;
      }
      
      .svg-box {
        width: 33.3%;
        cursor: pointer;
        height: 200px;
        transition: all 0.3s ease;
      }
      
      .svg-font {
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 15px;
        font-weight: 700;
        background: linear-gradient(105deg, #7f8dea 2.35%, #ec72dc 96.13%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        padding-top: 35px;
      } 
  
  }
  
  @media (min-width: 481px) and (max-width: 768px){
    .svg-container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding-top: 5px;
      }
      
       .svg-section:hover .draw {
        animation: drawLine 6s forwards;
      }
      
      .svg-box {
        width: 33.3%;
        cursor: pointer;
        transition: all 0.3s ease;
      }
      
      .svg-font {
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 26px;
        font-weight: 700;
        background: linear-gradient(105deg, #7f8dea 2.35%, #ec72dc 96.13%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
  
  }
  @media (min-width: 769px) and (max-width: 1024px){
    .svg-container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding-top: 5px;
      }
      
       .svg-section:hover .draw {
        animation: drawLine 6s forwards;
      }
      
      .svg-box {
        width: 33.3%;
        cursor: pointer;
        transition: all 0.3s ease;
      }
      
      .svg-font {
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 32px;
        font-weight: 700;
        background: linear-gradient(105deg, #7f8dea 2.35%, #ec72dc 96.13%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
  
  }
  @media (min-width: 1025px) {
    .svg-container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding-top: 50px;
      }
      
       .svg-section:hover .draw {
        animation: drawLine 6s forwards;
      }
      
      .svg-box {
        width: 33.3%;
        cursor: pointer;
        transition: all 0.3s ease;
      }
      
      .svg-font {
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 46px;
        font-weight: 700;
        background: linear-gradient(105deg, #7f8dea 2.35%, #ec72dc 96.13%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
  
  }
  @media (min-width: 1921px) {
    .svg-container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding-top: 50px;
      }
      
       .svg-section:hover .draw {
        animation: drawLine 6s forwards;
      }
      
      .svg-box {
        width: 33.3%;
        cursor: pointer;
        transition: all 0.3s ease;
      }
      
      .svg-font {
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 80px;
        font-weight: 700;
        background: linear-gradient(105deg, #7f8dea 2.35%, #ec72dc 96.13%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
  
  }
  