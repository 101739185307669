.card-container {
    width: 90%;
    margin: 0 auto;
  
    padding: 0 auto;
  }
  .main-row1 {
    display: flex;
  }
  .main-row2 {
    display: flex;
  }
  .heading-container {
    width: 400px;
    padding-top: 10px;
  }
  
  .card-heading {
    margin-bottom: 20px;
    border-radius: 40px;
    border: 1px solid #db07d7;
    width: fit-content;
    padding: 5px 8px;
    margin: 0 auto;
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-weight: 600;
    background: linear-gradient(90deg, #136ad5 0%, #db07d7 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .card1 {
    background-color: #201f1f;
    width: 30%;
    height: 150px;
    border: 1px solid#B2B0B0;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    align-items: center;
    margin: 5px 5px;
    transition: all 0.6s ease-in-out;
  
  }
  
  .card1 img {
    width: 80px;
    height: 80px;
    margin-bottom: 0px;
    border-radius: 8px;
  }
  
  .card1 h3 {
    color: #d3cfcf;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
  }
  
  .card1:hover {
    box-shadow: inset 4px -4px #00c7be;
    transform: scale(1.01);
    overflow: hidden;
    
  }
  
  /* this is mobile device */
  @media (max-width: 479px) {
    .card-container {
      width: 80%;
      margin: 0 auto;
      padding: 0 auto;
    }
    .main-row1 {
      display: block;
      padding-top: 30px;
    }
    .main-row2 {
      display: block;
    }
    .heading-container {
      width: 90px;
      
    }
    .card-heading {
      border-radius: 40px;
      border: 1px solid #db07d7;
      width: 150px;
      padding: 5px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      background: linear-gradient(90deg, #136ad5 0%, #db07d7 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  
    .card1 {
      background-color: #201f1f;
      width: 80%;
      height: 150px;
      border: 1px solid#B2B0B0;
      padding: 20px;
      border-radius: 8px;
      text-align: center;
      transition: transform 0.3s ease;
      align-items: center;
      margin: 5px 5px;
    }
  
    .card1 img {
      width: 80px;
      height: 80px;
      margin-bottom: 0px;
      border-radius: 8px;
    }
  
    .card1 h3 {
      color: #d3cfcf;
      font-family: Poppins;
      font-size: 18px;
      font-weight: 500;
    }
  
    .card1:hover {
      box-shadow: inset 4px -4px #00c7be;
      transform: scale(1.01);
      overflow: hidden;
    }
  }
  /* this is tablet device  */
  @media (min-width: 481px) and (max-width: 768px) {
    .card-container {
      width: 90%;
      margin: 0 auto;
  
      padding: 0 auto;
    }
    .main-row1 {
      display: flex;
    }
    .main-row2 {
      display: flex;
    }
    .heading-container {
      width: 180px;
      padding-top: 5px;
      padding-bottom: 30px;
    }
    .card-heading {
      text-align: center;
      margin-bottom: 20px;
      border-radius: 40px;
      border: 1px solid #db07d7;
      width: fit-content;
      padding: 5px 8px;
      margin: 0 auto;
      font-family: Poppins, sans-serif;
      font-size: 26px;
      font-weight: 600;
      background: linear-gradient(90deg, #136ad5 0%, #db07d7 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  
    .card1 {
      background-color: #201f1f;
      width: 30%;
      height: 150px;
      border: 1px solid#B2B0B0;
      padding: 20px;
      border-radius: 8px;
      text-align: center;
      transition: transform 0.3s ease;
      align-items: center;
      margin: 5px 5px;
    }
  
    .card1 img {
      width: 80px;
      height: 80px;
      margin-bottom: 0px;
      border-radius: 8px;
    }
  
    .card1 h3 {
      color: #d3cfcf;
      font-family: Poppins;
      font-size: 18px;
      font-weight: 500;
    }
  
    .card1:hover {
      box-shadow: inset 4px -4px #00c7be;
      transform: scale(1.01);
      overflow: hidden;
    }
  }
  
  /* this is small desktop device */
  @media (min-width: 769px) and (max-width:1024px) {
    .card-container {
      width: 90%;
      margin: 0 auto;
  
      padding: 0 auto;
    }
    .main-row1 {
      display: flex;
    }
    .main-row2 {
      display: flex;
    }
    .heading-container {
      width: 180px;
      padding-top: 20px;
      padding-bottom: 30px;
    }
    .card-heading {
      text-align: center;
      margin-bottom: 20px;
      border-radius: 40px;
      border: 1px solid #db07d7;
      width: fit-content;
      padding: 5px 8px;
      margin: 0 auto;
      font-family: Poppins, sans-serif;
      font-size: 26px;
      font-weight: 600;
      background: linear-gradient(90deg, #136ad5 0%, #db07d7 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  
    .card1 {
      background-color: #201f1f;
      width: 30%;
      height: 150px;
      border: 1px solid#B2B0B0;
      padding: 20px;
      border-radius: 8px;
      text-align: center;
      transition: transform 0.3s ease;
      align-items: center;
      margin: 5px 5px;
    }
  
    .card1 img {
      width: 80px;
      height: 80px;
      margin-bottom: 0px;
      border-radius: 8px;
    }
  
    .card1 h3 {
      color: #d3cfcf;
      font-family: Poppins;
      font-size: 18px;
      font-weight: 500;
    }
  
    .card1:hover {
      box-shadow: inset 4px -4px #00c7be;
      transform: scale(1.01);
      overflow: hidden;
    }
  }
  /* this is larger desktop device */
  @media (min-width: 1025px) {
    .card-container {
      width: 90%;
      margin: 0 auto;
  
      padding: 0 auto;
    }
    .main-row1 {
      display: flex;
    }
    .main-row2 {
      display: flex;
    }
    .heading-container {
      width: 210px;
      padding-top: 50px;
      padding-bottom: 30px;
    }
    .card-heading {
      text-align: center;
      margin-bottom: 20px;
      border-radius: 40px;
      border: 1px solid #db07d7;
      width: auto;
      padding: 5px 8px;
      margin: 0 auto;
      font-family: Poppins, sans-serif;
      font-size: 30px;
      font-weight: 600;
      background: linear-gradient(90deg, #136ad5 0%, #db07d7 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  
    .card1 {
      background-color: #201f1f;
      width: 30%;
      height: 150px;
      border: 1px solid#B2B0B0;
      padding: 20px;
      border-radius: 8px;
      text-align: center;
      transition: transform 0.3s ease;
      align-items: center;
      margin: 5px 5px;
    }
  
    .card1 img {
      width: 80px;
      height: 80px;
      margin-bottom: 0px;
      border-radius: 8px;
    }
  
    .card1 h3 {
      color: #d3cfcf;
      font-family: Poppins;
      font-size: 18px;
      font-weight: 500;
    }
  
    .card1:hover {
      box-shadow: inset 4px -4px #00c7be;
      transform: scale(1.01);
      overflow: hidden;
    }
  }
  /* this is 4k device */
  @media (min-width: 2560px) {
    .card-container {
      width: 80%;
      margin: 0 auto;
  
      padding: 0 auto;
    }
    .main-row1 {
      display: flex;
    }
    .main-row2 {
      display: flex;
    }
    .heading-container {
      width: 400px;
      padding-top: 60px;
      padding-bottom: 60px;
    }
    .card-heading {
      text-align: center;
      margin-bottom: 20px;
      border-radius: 40px;
      border: 1px solid #db07d7;
      width: fit-content;
      padding: 5px 8px;
      margin: 0 auto;
      font-family: Poppins, sans-serif;
      font-size: 60px;
      font-weight: 600;
      background: linear-gradient(90deg, #136ad5 0%, #db07d7 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  
    .card1 {
      background-color: #201f1f;
      width: 30%;
      height: 300px;
      border: 1px solid#B2B0B0;
      padding: 20px;
      border-radius: 8px;
      text-align: center;
      transition: transform 0.3s ease;
      align-items: center;
      margin: 5px 5px;
    }
  
    .card1 img {
      width: 120px;
      height: 120px;
      margin-bottom: 0px;
      border-radius: 8px;
    }
  
    .card1 h3 {
      color: #d3cfcf;
      font-family: Poppins;
      font-size: 36px;
      font-weight: 500;
    }
  
    .card1:hover {
      box-shadow: inset 4px -4px #00c7be;
      transform: scale(1.01);
      overflow: hidden;
    }
  }
  
  
  
  
  