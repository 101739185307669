.footer-container {
  background-color: #3a3a3a;
  padding: 20px 0;
  color: white;
  text-align: center;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-logo {
  font-size: 24px;
  font-weight: bold;
  color: #ffc107;
  margin-bottom: 10px;
}

/* Footer links */
.footer-links {
  text-align: justify;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.footer-links p {
  margin: 0;
  padding-bottom: 10px;
}

.policies,
.policies2 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.policies a,
.policies2 a {
  color: white;
  text-decoration: none;
  font-size: 14px;
}

.policies a:hover,
.policies2 a:hover {
  color: #ffc107;
}

/* Social icons */
.social-icons {
  margin-top: 15px;
}

.social-icons p {
  margin: 0 0 10px;
}

.icons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.social-images {
  width: 20px;
  height: auto;
  transition: transform 0.3s ease;
}

.social-images:hover {
  transform: scale(1.2);
}

/* Responsive Design */

/* Laptops (769px to 1200px) */
@media (min-width: 769px) and (max-width: 1200px) {
  .footer-content {
    flex-direction: row; /* Row layout */
    justify-content: space-between;
  }

  .footer-logo {
    flex: 1; /* First column: Logo */
    /* text-align: left; */
  }

  .footer-links {
    flex: 2; /* Second column: Links */
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .policies,
  .policies2 {
    justify-content: center;
    gap: 10px;
  }

  .social-icons {
    flex: 1; /* Third column: Social icons */
    /* text-align: right; */
  }
}

/* Desktop (1201px and up) */
@media (min-width: 1201px) {
  .footer-content {
    flex-direction: row;
    justify-content: space-between;
  }

  .footer-logo {
    flex: 1;
    /* text-align: left; */
  }
 

  .footer-links {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .social-icons {
    flex: 1;
  }
}

/* Mobile (up to 768px) */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-logo {
    text-align: center;
    padding-left: 0;
  }

  .footer-links {
    flex-direction: column;
  }

  .social-icons {
    text-align: center;
  }
}

/* 4K Displays (2000px and up) */
@media (min-width: 2000px) {
  .footer-container {
    padding: 40px 0;
  }

  .footer-logo {
    font-size: 50px;
  }

  .policies a,
  .policies2 a {
    font-size: 28px;
  }

  .social-images {
    width: 40px;
  }
  .footer-links p{
    font-size: 30px;
  }
 .social-icons p{
  font-size: 30px;
 }
}
